import React from 'react';
import CakePageContent from '@unicat/CakePageContent';
import { gallery } from '@config/Portfolio/index.json';
import Footer from '@unicat/Footer';

const CakeDetailsPage = () => {
  return (
    <div className={'default-container'}>
      <CakePageContent image={gallery[0]} />
      <Footer />
    </div>
  );
};

export default CakeDetailsPage;
